const AdvertiserIndex = () => import('@/modules/advertisers/views/AdvertiserIndex.vue')
const AdvertiserShow = () => import('@/modules/advertisers/views/AdvertiserShow.vue')
const AdvertiserEdit = () => import('@/modules/advertisers/views/AdvertiserEdit.vue')

export default [
  {
    path: '/advertisers',
    name: 'advertisers.index',
    component: AdvertiserIndex,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/advertisers/create',
    name: 'advertisers.create',
    component: AdvertiserEdit,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/advertisers/:id',
    name: 'advertisers.show',
    component: AdvertiserShow,
    meta: { requiresAuthentication: true }
  },
  {
    path: '/advertisers/:id/edit',
    name: 'advertisers.edit',
    component: AdvertiserEdit,
    meta: { requiresAuthentication: true }
  }
]
